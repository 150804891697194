var forEmployees = document.getElementById('forEmployees');
var forEmployeesDiv = document.getElementById('forEmployeesDiv');
var forEmployers = document.getElementById('forEmployers');
var forEmployersDiv = document.getElementById('forEmployersDiv');
var dropdownMenuButton = document.getElementById('dropdownMenuButton2');
var mainLogo = document.getElementById('mainLogo');

if (forEmployees) {
    forEmployees.addEventListener('click', function () {
        this.classList.remove('inactive-how');
        this.classList.add('active-how');
        forEmployers.classList.remove('active-how');
        forEmployers.classList.add('inactive-how');
        forEmployeesDiv.classList.remove('d-none');
        forEmployersDiv.classList.add('d-none');

    })
}

if (forEmployers) {
    forEmployers.addEventListener('click', function () {
        this.classList.remove('inactive-how');
        this.classList.add('active-how');
        forEmployees.classList.remove('active-how');
        forEmployees.classList.add('inactive-how');
        forEmployeesDiv.classList.add('d-none');
        forEmployersDiv.classList.remove('d-none');
    })
}

// dropdownMenuButton.addEventListener('click', function() {
//     if (mainLogo.classList.contains('blue-logo')) {
//         mainLogo.classList.remove('blue-logo');
//         mainLogo.classList.add('white-logo');
//     } else if (mainLogo.classList.contains('white-logo')) {
//         mainLogo.classList.add('blue-logo');
//         mainLogo.classList.remove('white-logo');
//     }
// })